import { InjectionToken } from '@angular/core';
import { HttpErrorHandler } from '../models/http-error.handler';
import { RuntimeErrorHandler } from '../models/runtime-error.handler';

export const HTTP_ERROR_HANDLERS: InjectionToken<HttpErrorHandler> =
  new InjectionToken<HttpErrorHandler>('HTTP_ERROR_HANDLERS');

export const RUNTIME_ERROR_HANDLER: InjectionToken<RuntimeErrorHandler> =
  new InjectionToken<RuntimeErrorHandler>('RUNTIME_ERROR_HANDLER');

export const DEFAULT_HTTP_ERROR_HANDLERS: InjectionToken<HttpErrorHandler> =
  new InjectionToken<HttpErrorHandler>('DEFAULT_HTTP_ERROR_HANDLERS');
