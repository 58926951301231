<div class="ni-notification-toast" [class]="className">
  <div *ngIf="options.closeButton" aria-label="Close" class="toast-close-button" (click)="remove()">
    <ni-icon name="close"></ni-icon>
  </div>
  <div *ngIf="title" [attr.aria-label]="title" [class]="options.titleClass">
    {{ title }}
    <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
  </div>
  <div
    *ngIf="message && options.enableHtml"
    aria-live="polite"
    role="alertdialog"
    [class]="options.messageClass"
    [innerHTML]="message"
  ></div>
  <div
    *ngIf="message && !options.enableHtml"
    aria-live="polite"
    role="alertdialog"
    [attr.aria-label]="message"
    [class]="options.messageClass"
  >
    {{ message }}
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
