import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  inject,
  Input
} from '@angular/core';
import { NgControl as AngNgControl } from '@angular/forms';
import { Nullish } from '@fe/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputRefDirective } from '../directives/input-ref.directive';
import { AbstractControl } from '../models/abstract-control';
import { CtrlColorScheme } from '../models/ctrl-color-scheme';
import { CtrlMessage } from '../models/ctrl-message';
import { CtrlMessageType } from '../models/ctrl-message-type';
import { NgControl } from '../models/ng-control';
import { ControlsService } from '../services/controls.service';

@UntilDestroy()
@Component({ template: '' })
export abstract class AbstractCtrlWrapperComponent implements AfterContentInit {
  @ContentChild(InputRefDirective) inputRef!: InputRefDirective;
  @ContentChild(AngNgControl) ngControl!: NgControl;

  @Input() label: Nullish<string>;
  @Input() key: Nullish<string>;

  protected controlService: ControlsService = inject(ControlsService);
  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public id: Nullish<string>;
  public ctrlMessage: Nullish<CtrlMessage>;
  public colorScheme!: CtrlColorScheme;

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  ngAfterContentInit(): void {
    this.colorScheme = this.getColorScheme();
    this.id = this.inputRef.id;
    this.controlService
      .getMessage$(this.control, this.key)
      .pipe(untilDestroyed(this))
      .subscribe((message: Nullish<CtrlMessage>) => {
        this.ctrlMessage = message;
        this.colorScheme = this.getColorScheme();
        this.cdr.detectChanges();
      });
  }

  private getColorScheme(): CtrlColorScheme {
    let colorScheme: CtrlColorScheme = CtrlColorScheme.DEFAULT;
    if (this.ctrlMessage?.type === CtrlMessageType.ERROR) {
      colorScheme = CtrlColorScheme.ERROR;
    }
    return colorScheme;
  }
}
