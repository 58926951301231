import { DOCUMENT } from '@angular/common';
import { inject, Renderer2 } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class AbstractModalDialogComponent {
  private readonly unsubscribe$: Subject<void>;

  protected confirmSubject$: Subject<unknown>;
  protected cancelSubject$: Subject<unknown>;
  protected bsModalRef: BsModalRef = inject(BsModalRef);
  protected renderer: Renderer2 = inject(Renderer2);
  protected bsModalService: BsModalService = inject(BsModalService);
  protected document: Document = inject(DOCUMENT);

  public confirm$: Observable<unknown>;
  public cancel$: Observable<unknown>;

  constructor() {
    this.unsubscribe$ = new Subject<void>();
    this.confirmSubject$ = new Subject<unknown>();
    this.cancelSubject$ = new Subject<unknown>();
    this.confirm$ = this.confirmSubject$.pipe(takeUntil(this.unsubscribe$));
    this.cancel$ = this.cancelSubject$.pipe(takeUntil(this.unsubscribe$));
    this.bsModalRef.onHidden?.subscribe(() => {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    });
    this.adjustBodySize();
  }

  onConfirm(data?: unknown): void {
    this.bsModalRef.hide();
    this.confirmSubject$.next(data);
  }

  onCancel(data?: unknown): void {
    this.bsModalRef.hide();
    this.cancelSubject$.next(data);
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  private adjustBodySize(): void {
    this.bsModalService.onShown.subscribe(() => {
      this.renderer.setStyle(this.document.body, 'width', 'calc(100% - 16px)');
    });
    this.bsModalService.onHide.subscribe(() => {
      this.renderer.setStyle(this.document.body, 'width', '100%');
    });
  }
}
