import { inject } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

export abstract class AbstractNotificationComponent extends Toast {
  public initialState?: unknown;
  public className: string;

  constructor() {
    super(inject(ToastrService), inject(ToastPackage));
    this.className = this.toastPackage.toastType.toLowerCase();
  }
}
