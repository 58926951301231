import { KeyValue, KeyValueTuple } from '@fe/models';

/**
 * Convert object entries like [key, value] to KeyValue array.
 *
 * @param {Object} entries Object entries like [key, value]. Usually you can get it using Object.entries(obj) method.
 * @returns {Array<KeyValue>} Returns array of KeyValue.
 * @example
 *
 * const obj = { a: 1, b: null, c: '' };
 * getKeyValues(Object.entries(obj)) // [{ key: 'a', value: 1 }, { key: 'b', value: null }, { key: 'c', value: '' }]
 */
export function getKeyValues<T>(entries: KeyValueTuple<T>[]): KeyValue<T>[] {
  return entries.map(([key, value]: KeyValueTuple<T>) => ({ key, value }));
}
