import { Injectable } from '@angular/core';
import { ScrollTarget } from '@fe/common';
import { UiMessage, UiMessageScope, UiMessagesService, UiMessagesUtils } from '@fe/messages';
import { NotificationService } from '@fe/notifications';
import { ScrollService } from '@fe/scroll';

@Injectable({ providedIn: 'root' })
export class AppMessagesService {
  constructor(
    private notificationService: NotificationService,
    private scrollService: ScrollService,
    private ukgUiMessagesService: UiMessagesService
  ) {}

  showMessages(messages: UiMessage[]): void {
    this.showGlobalMessages(messages);
    this.scrollToFirstLocalMessageIfExists(messages);
  }

  private showGlobalMessages(messages: UiMessage[]): void {
    const errorMessages: UiMessage[] = UiMessagesUtils.getGlobalErrors(messages);
    errorMessages.forEach((message: UiMessage) => {
      const text: string = this.ukgUiMessagesService.getMessageText(message);
      this.notificationService.showError({ message: text });
    });
  }

  private scrollToFirstLocalMessageIfExists(messages: UiMessage[]): void {
    const hasLocalMessages: boolean = messages.some(
      (message: UiMessage) => message.scope === UiMessageScope.LOCAL
    );
    if (hasLocalMessages) {
      this.scrollService.scrollTo(ScrollTarget.CONTROL_ERROR);
    }
  }
}
