import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ApiInfo } from '@dev-portal/models/api-info';
import { ApplicationScopesProvider } from '@dev-portal/providers';
import { Auth0Service } from '@dev-portal/services/auth0.service';
import { AppStore } from '@dev-portal/store/app.store';
import { forkJoin, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationDetailsPageResolver implements Resolve<unknown> {
  constructor(private auth0Service: Auth0Service, private appStore: AppStore) {}

  resolve(): Observable<unknown> {
    return forkJoin({
      authScopes: this.loadAuthScopes$()
    });
  }

  private loadAuthScopes$(): Observable<unknown> {
    return this.auth0Service.fetchApiInfo$().pipe(
      tap(() => {
        const apiInfo: ApiInfo = this.appStore.getApiInfo();
        ApplicationScopesProvider.set(apiInfo.scopes);
      })
    );
  }
}
