import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { ErrorsStateModel } from '../models/errors-state-model';

@Injectable({ providedIn: 'root' })
export class ErrorsState extends RxState<ErrorsStateModel> {
  private readonly initialState: ErrorsStateModel = {
    httpError: null
  };

  private get state(): ErrorsStateModel {
    return this.get();
  }

  constructor() {
    super();
    this.set(this.initialState);
  }

  patch(newState: Partial<ErrorsStateModel>): void {
    this.set({ ...this.state, ...newState });
  }
}
