import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { DialogFooterComponent } from '../dialog-footer.component';
import { DialogTitleComponent } from '../dialog-title.component';

@Component({
  selector: 'ni-modal-dialog',
  templateUrl: 'modal-dialog.component.html',
  host: { class: 'ni-modal-dialog' }
})
export class ModalDialogComponent {
  @ContentChild(DialogTitleComponent) titleContent: DialogTitleComponent | undefined;
  @ContentChild(DialogFooterComponent) footerContent: DialogFooterComponent | undefined;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Input() closable: boolean = true;

  close(): void {
    this.closeModal.emit();
  }
}
