import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UiMessage } from '../models/ui-message';
import { UiMessageScope } from '../models/ui-message-scope';

@Injectable({ providedIn: 'root' })
export class UiMessagesEmitter {
  public messages$: Observable<UiMessage[]>;
  public localMessages$: Observable<UiMessage[]>;

  private messagesSubject$: Subject<UiMessage[]>;
  private localMessagesSubject$: Subject<UiMessage[]>;

  constructor() {
    this.messagesSubject$ = new Subject<UiMessage[]>();
    this.localMessagesSubject$ = new Subject<UiMessage[]>();
    this.messages$ = this.messagesSubject$.asObservable();
    this.localMessages$ = this.localMessagesSubject$.asObservable();
  }

  emit(messages: UiMessage[]): void {
    this.messagesSubject$.next(messages);
    this.localMessagesSubject$.next(this.getLocalMessages(messages));
  }

  private getLocalMessages(messages: UiMessage[]): UiMessage[] {
    return messages.filter((message: UiMessage) => {
      return message.scope === UiMessageScope.LOCAL;
    });
  }
}
