import { NgIf } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiScreenLockerComponent } from './components/full-screen-locker/ui-screen-locker.component';
import { StaticInjector } from './static-injector';

@NgModule({
  imports: [NgIf],
  declarations: [UiScreenLockerComponent],
  exports: [UiScreenLockerComponent]
})
export class UiLockerModule {
  static forRoot(): ModuleWithProviders<UiLockerModule> {
    return {
      ngModule: UiLockerModule,
      providers: [StaticInjector]
    };
  }

  constructor(private staticInjector: StaticInjector) {}
}
