import { ApiInfo } from '@dev-portal/models/api-info';
import { Application } from '@dev-portal/models/application';
import { ApplicationDetails } from '@dev-portal/models/application-details';

export class SetApiInfo {
  static readonly type: string = '[Root] Set api info';

  constructor(public apiInfo: ApiInfo) {}
}

export class SetApplications {
  static readonly type: string = '[Root] Set applications';

  constructor(public applications: Application[]) {}
}

export class SetApplicationDetails {
  static readonly type: string = '[Root] Set application details';

  constructor(public applicationDetails: ApplicationDetails) {}
}

export class DeleteApplication {
  static readonly type: string = '[Root] Delete application';

  constructor(public applicationId: string) {}
}

export class ResetApplicationDetails {
  static readonly type: string = '[Root] Reset application details';
}
