import { HttpParams } from '@angular/common/http';
import { KeyValue } from '@fe/models';
import { getEntriesWithNotNullishValues } from '../collections';

/**
 * Create HttpParams instance from object. Uses object keys as parameter names and its values as parameter values.
 * Skip properties with nullish value.
 *
 * @param {Object} queryParams The source object.
 * @returns {Array<KeyValue>} Returns HttpParams instance create bases on source object keys/values.
 * @example
 *
 * const obj = { a: 1, b: null, c: '', d: false, e: 0 };
 * HttpUtils.createQueryParams(obj) // HttpParams instance, where HttpParams.toString() is 'a=1&c=&d=false&e=0'
 */
export function createQueryParams(queryParams: { [key: string]: any }): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  getEntriesWithNotNullishValues(queryParams).forEach((entry: KeyValue<any>) => {
    httpParams = buildParam(httpParams, entry.key, entry.value);
  });
  return httpParams;
}

function buildParam(httpParams: HttpParams, key: string, value: any): HttpParams {
  if (Array.isArray(value)) {
    value.forEach((param: HttpParams) => {
      httpParams = httpParams.append(key, param.toString());
    });
  } else {
    httpParams = httpParams.append(key, value.toString());
  }
  return httpParams;
}
