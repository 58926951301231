<ni-scroll-target *ngIf="ctrlMessage" [name]="ScrollTarget.CONTROL_ERROR"></ni-scroll-target>
<div class="ctrl-wrapper ctrl-wrapper--{{colorScheme}}">
  <div class="input-container">
    <ng-content></ng-content>
    <label *ngIf="isRendered" class="form-label" [class.active]="hasValue()">{{ label }}</label>
    <div *ngIf="inputSectionEnd" class="input-section-end">
      <ng-content select="[ndpInputEndItem]"></ng-content>
    </div>
  </div>
  <div class="helper-text">
    <span *ngIf="ctrlMessage" [innerHTML]="ctrlMessage.text | trustHtml"></span>
    <ng-container *ngIf="inputHint && !ctrlMessage" class="helper-text">
      <ng-content select="[ndpInputHint]"></ng-content>
    </ng-container>
  </div>
</div>
