<ni-modal-dialog (closeModal)="onClose()">
  <ni-dialog-title>
    <ng-container
      *ngIf="titleTemplate"
      [ngTemplateOutlet]="titleTemplate"
      [ngTemplateOutletContext]="templateContext"
    ></ng-container>
    <span *ngIf="!titleTemplate">{{ initialState?.titleTextOrTemplate }}</span>
  </ni-dialog-title>
  <ni-dialog-body>
    <ng-container
      *ngIf="bodyTemplate"
      [ngTemplateOutlet]="bodyTemplate"
      [ngTemplateOutletContext]="templateContext"
    ></ng-container>
    <div *ngIf="!bodyTemplate">{{ initialState?.bodyTextOrTemplate }}</div>
  </ni-dialog-body>
  <ni-dialog-footer class="w-100 grid">
    <button theme="secondary" class="g-col-6" (click)="onClose()">
      {{ initialState?.cancelBtnText }}
    </button>
    <button theme="primary" class="g-col-6" niSetFocus (click)="onConfirm()">
      {{ initialState?.confirmBtnText }}
    </button>
  </ni-dialog-footer>
</ni-modal-dialog>
