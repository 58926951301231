import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@dev-portal/config/provider-tokens';
import { AppEnvironment } from '@dev-portal/environments';
import { UiLocking } from '@fe/ui-locker';
import * as Api from '@nursa/contracts-public-api-auth';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Auth0Api {
  constructor(private http: HttpClient, @Inject(ENV) private environment: AppEnvironment) {}

  @UiLocking()
  getApiInfo$(): Observable<Api.GetOidcResourceServerResponse> {
    const url: string = `${this.environment.authServerUrl}/resource-server/public-api`;
    return this.http.get<Api.GetOidcResourceServerResponse>(url);
  }
}
