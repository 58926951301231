import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UiLockerStore } from '../../store/ui-locker.store';

@Component({
  selector: 'ni-screen-ui-locker',
  templateUrl: 'ui-screen-locker.component.html',
  host: { class: 'ui-screen-locker' }
})
export class UiScreenLockerComponent implements OnInit {
  public locked: boolean = false;
  private keyDownHandler = (event: KeyboardEvent): void => event.preventDefault();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: UiLockerStore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.locked$.subscribe((locked: boolean) => {
      if (locked) {
        document.addEventListener('keydown', this.keyDownHandler);
      } else {
        document.removeEventListener('keydown', this.keyDownHandler);
      }
      this.locked = locked;
      this.cdr.detectChanges();
    });
  }
}
