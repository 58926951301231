import { KeyValue, KeyValueTuple } from '@fe/models';
import { getKeyValues } from './get-key-values';
import { isNotNullish } from './nullish-check';

/**
 * Convert object entries [key, value] to KeyValue array that contains only entries with not nullish value.
 *
 * @param {Object} object The object to iterate.
 * @returns {Array<KeyValue>} Returns collection of entries with not nullish values.
 * @example
 *
 * const obj = { a: 1, b: null, c: '' };
 * getEntriesWithNotNullishValues(obj) // [{ key: 'a', value: 1 }, { key: 'c', value: '' }]
 */
export function getEntriesWithNotNullishValues<T>(object: object): KeyValue<T>[] {
  const entries: KeyValueTuple<T>[] = Object.entries(object || {}).filter(
    ([, value]: KeyValueTuple<T>) => isNotNullish(value)
  );
  return getKeyValues(entries);
}
