/* eslint-disable @typescript-eslint/no-explicit-any */

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StaticInjector } from '../static-injector';
import { UiLockerStore } from '../store/ui-locker.store';

export type EndpointMethod = (...args: any[]) => Observable<any>;
export type EndpointMethodDescriptor = TypedPropertyDescriptor<EndpointMethod>;
export type EndpointMethodDecorator = (
  target: object,
  propertyKey: string,
  descriptor: EndpointMethodDescriptor
) => EndpointMethodDescriptor;

export function UiLocking(): EndpointMethodDecorator {
  return function (
    target: object,
    propertyKey: string,
    descriptor: EndpointMethodDescriptor
  ): EndpointMethodDescriptor {
    const originalFunction: EndpointMethod = descriptor.value!;
    descriptor.value = function (...args: any[]): Observable<any> {
      const store: UiLockerStore = StaticInjector.get(UiLockerStore);
      store.incrementPendingRequests();
      const result: Observable<any> = originalFunction.apply(this, args);
      return result.pipe(finalize(() => store.decrementPendingRequests()));
    };
    return descriptor;
  };
}
