import { Injectable } from '@angular/core';
import { Auth0Api } from '@dev-portal/api/auth0.api';
import { AppStore } from '@dev-portal/store/app.store';
import * as Api from '@nursa/contracts-public-api-auth';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Auth0Service {
  constructor(private auth0Api: Auth0Api, private appStore: AppStore) {}

  fetchApiInfo$(): Observable<unknown> {
    return this.auth0Api.getApiInfo$().pipe(
      tap((apiInfo: Api.GetOidcResourceServerResponse) => {
        this.appStore.setApiInfo(apiInfo);
      })
    );
  }
}
