import { isEmpty as _isEmpty } from 'radash';

/**
 * Checks if `value` is an empty object, collection, map, or set.
 *
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 *
 * Array-like values such as `arguments` objects, arrays, buffers, strings
 * are considered empty if they have a `length` of `0`.
 * Similarly, maps and sets are considered empty if they have a `size` of `0`.
 *
 * @param value The value to check.
 * @returns Returns `true` if `value` is empty, else `false`.
 * @example
 *
 * isEmpty([])         // => true
 * isEmpty('')         // => true
 * isEmpty(null)       // => true
 * isEmpty(true)       // => true
 * isEmpty(false)      // => true
 *
 * isEmpty(1)          // => false
 * isEmpty('hello')    // => false
 * isEmpty(['hello'])  // => false
 * isEmpty([1, 2, 3])  // => false
 * isEmpty({ 'a': 1 }) // => false
 */
export function isEmpty(value: unknown): boolean {
  return _isEmpty(value);
}

export function isNotEmpty(value: unknown): boolean {
  return !_isEmpty(value);
}
