import {
  AbstractControlOptions as AngAbstractControlOptions,
  AsyncValidatorFn as AngAsyncValidatorFn,
  FormGroup as AngFormGroup,
  ValidatorFn as AngValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs';
import { DEFAULT_FORM_UPDATE_OPTIONS } from '../config/default-form-set-value-options';
import { FormSetValueOptions } from './form-set-value-options';
import { AbstractControl } from './abstract-control';
import { FormControl } from './form-control';
import { FormControls } from './form-controls';
import { FormValue } from './form-value';

export class Form<TControls extends FormControls<TControls>>
  extends AngFormGroup
  implements AbstractControl
{
  public readonly touchedChanges!: Observable<boolean>;
  public override controls!: FormControls<TControls>;
  public override valueChanges!: Observable<FormValue<TControls>>;
  public override value!: FormValue<TControls>;

  constructor(
    controls: TControls,
    validatorOrOpts?: AngValidatorFn | AngValidatorFn[] | AngAbstractControlOptions | null,
    asyncValidator?: AngAsyncValidatorFn | AngAsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  override setValue(fv: FormValue<TControls>, options: FormSetValueOptions = {}): void {
    options = { ...DEFAULT_FORM_UPDATE_OPTIONS, ...options };
    super.setValue(fv, options);
  }

  override patchValue(fv: Partial<FormValue<TControls>>, options: FormSetValueOptions = {}): void {
    options = { ...DEFAULT_FORM_UPDATE_OPTIONS, ...options };
    super.patchValue(fv, options);
  }

  override getRawValue(): FormValue<TControls> {
    return super.getRawValue();
  }

  setDisabled(
    state: { [P in keyof TControls]?: boolean },
    options: FormSetValueOptions = {}
  ): void {
    options = { ...DEFAULT_FORM_UPDATE_OPTIONS, ...options };
    (Object.keys(state) as (keyof TControls)[]).forEach((property: keyof TControls) => {
      const isDisabled: boolean = state[property]!;
      const control: FormControl<unknown> = this.controls[property];
      isDisabled
        ? control.disable({ onlySelf: options.onlySelf, emitEvent: options.emitEvent })
        : control.enable({ onlySelf: options.onlySelf, emitEvent: options.emitEvent });
    });
  }

  markAllAsUntouched(opts: { onlySelf?: boolean } = {}): void {
    this.markAsUntouched(opts);
  }
}
