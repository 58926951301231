import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpError } from '../models/http-error';
import { HttpErrorHandler } from '../models/http-error.handler';

@Injectable()
export class DefaultHttpErrorHandler extends HttpErrorHandler {
  handle(httpError: HttpError): void {
    console.error('HTTP error:', httpError);
  }

  match(statusCode: HttpStatusCode): boolean {
    return statusCode >= 400;
  }
}
