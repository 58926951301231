import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { UiLockerStateModel } from '../models/ui-locker-state-model';

@Injectable({ providedIn: 'root' })
export class UiLockerState extends RxState<UiLockerStateModel> {
  private readonly initialState: UiLockerStateModel = {
    pendingRequestsCount: 0,
    locked: false
  };

  private get state(): UiLockerStateModel {
    return this.get();
  }

  constructor() {
    super();
    this.set(this.initialState);
  }

  patch(newState: Partial<UiLockerStateModel>): void {
    this.set({ ...this.state, ...newState });
  }

  reset(): void {
    this.set(this.initialState);
  }
}
