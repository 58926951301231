import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { IconName } from '../models';
import { NiIconsRegistry } from '../ni-icons.registry';

@Component({
  standalone: true,
  selector: 'ni-icon',
  exportAs: 'niIcon',
  template: '',
  host: {
    role: 'img',
    class: 'ni-icon',
    'aria-hidden': 'true',
    '[class.ni-icon-inline]': 'inline'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NiIconComponent {
  private iconName!: IconName;
  private svgIcon: SVGElement | undefined;

  @Input() inline: boolean = false;

  @Input()
  set name(iconName: IconName) {
    if (iconName !== this.iconName) {
      if (this.svgIcon) {
        this.clearIcon();
      }
      if (iconName) {
        this.updateIcon(iconName);
      }
      this.iconName = iconName;
    }
  }

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  private updateIcon(iconName: IconName): void {
    const svgText: string | undefined = NiIconsRegistry.get(iconName);
    if (svgText) {
      this.svgIcon = this.toSvgFromString(svgText);
      this.elementRef.nativeElement.appendChild(this.svgIcon);
    }
  }

  private clearIcon(): void {
    this.elementRef.nativeElement.removeChild(this.svgIcon);
  }

  private toSvgFromString(content: string): SVGElement {
    const div: HTMLDivElement = this.document.createElement('div');
    div.innerHTML = content;
    const svg: SVGElement = div.querySelector('svg') as SVGElement;
    svg.setAttribute('fit', '');
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    svg.setAttribute('focusable', 'false');
    return svg;
  }
}
