import { Injectable, Injector, ProviderToken } from '@angular/core';

@Injectable()
export class StaticInjector {
  private static injector: Injector;

  static get<T>(token: ProviderToken<T>): T {
    return StaticInjector.injector.get<T>(token);
  }

  constructor(injector: Injector) {
    StaticInjector.injector = injector;
  }
}
