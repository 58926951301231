<!-- Inline styles are here to solve the problem of flashes of unaligned content -->
<div class="fullscreen-block" *ngIf="locked">
  <div
    class="logo-loader"
    style="
      width: 15rem;
      height: 15rem;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0.4;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <svg
      class="parts"
      width="120"
      height="120"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="
        position: absolute;
        animation: parts-animation 1s ease-in-out infinite both;
        width: 12rem;
        height: 12rem;
      "
    >
      <path
        d="M6.625 6.75V7.1875H6.1875C3.0625 7.1875 0.5 9.65625 0.3125 12.75H4.25C4.4375 11.8438 5.21875 11.1562 6.1875 11.1562H6.625H10.5625V6.75C10.5625 5.78125 11.25 4.96875 12.1563 4.8125V0.8125C9.0625 1 6.625 3.59375 6.625 6.75Z"
        fill="#5924B0"
      />
      <path
        d="M18.4062 6.75V7.1875H18.8437C21.9687 7.1875 24.5312 9.65625 24.7187 12.75H20.7812C20.5937 11.8438 19.8125 11.1562 18.8437 11.1562H18.4062H14.4687V6.75C14.4687 5.78125 13.7812 4.96875 12.875 4.8125V0.8125C15.9687 1 18.4062 3.59375 18.4062 6.75Z"
        fill="#79CFF0"
      />
      <path
        d="M6.625 19.4688V19.0312H6.1875C3.0625 19.0312 0.5 16.5625 0.3125 13.4688H4.25C4.4375 14.375 5.21875 15.0625 6.1875 15.0625H6.625H10.5625V19.4688C10.5625 20.4375 11.25 21.25 12.1563 21.4062V25.375C9.0625 25.1875 6.625 22.5938 6.625 19.4688Z"
        fill="#79CFF0"
      />
      <path
        d="M18.4062 19.4688V19.0312H18.8437C21.9687 19.0312 24.5312 16.5625 24.7187 13.4688H20.7812C20.5937 14.375 19.8125 15.0625 18.8437 15.0625H18.4062H14.4687V19.4688C14.4687 20.4375 13.7812 21.25 12.875 21.4062V25.375C15.9687 25.1875 18.4062 22.5938 18.4062 19.4688Z"
        fill="#5924B0"
      />
    </svg>
    <svg
      class="circles"
      width="120"
      height="120"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="
        position: absolute;
        animation: circles-animation 1s ease-in-out infinite reverse both;
        width: 15rem;
        height: 15rem;
      "
    >
      <circle cx="2.96875" cy="3.46875" r="2.65625" fill="#5924B0" />
      <circle cx="22.0625" cy="3.5" r="2.65625" fill="#79CFF0" />
      <circle cx="2.96875" cy="22.7188" r="2.65625" fill="#79CFF0" />
      <circle cx="22.0625" cy="22.7188" r="2.65625" fill="#5924B0" />
    </svg>
  </div>
</div>
