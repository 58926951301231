import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UiLockerState } from './ui-locker.state';

@Injectable({ providedIn: 'root' })
export class UiLockerStore {
  public locked$: Observable<boolean> = this.state.select('locked');

  constructor(private state: UiLockerState) {}

  setLocked(isLocked: boolean): void {
    this.state.patch({ locked: isLocked });
  }

  incrementPendingRequests(): void {
    const pendingRequestsCount: number = this.state.get('pendingRequestsCount') + 1;
    this.state.patch({ pendingRequestsCount, locked: true });
  }

  decrementPendingRequests(): void {
    const pendingRequestsCount: number = Math.max(0, this.state.get('pendingRequestsCount') - 1);
    this.state.patch({ pendingRequestsCount, locked: pendingRequestsCount > 0 });
  }
}
