import { AppEnvironment } from '@dev-portal/environments';
import { baseEnvironmentValues } from './config/base-environment-values';

const baseApiUrl: string = 'https://public-api.stage.nursa.com';
const authServerUrl: string = `${baseApiUrl}/api/v1/auth/oidc`;

export const environment: AppEnvironment = {
  ...baseEnvironmentValues,
  stage: true,
  name: 'Stage',
  host: 'https://developers.stage.nursa.com',
  apiUrl: `${baseApiUrl}/api/oidc`,
  authServerUrl: authServerUrl,
  auth0Config: {
    domain: authServerUrl,
    clientId: 'oZz6w74YnCacpBlw9jth4iGDnUFupOJl',
    issuer: 'https://auth.stage.nursa.com/',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      scope: 'openid profile offline_access oidc-application:read oidc-application:write',
      redirect_uri: `${window.location.origin}/applications`,
      audience: `${baseApiUrl}/`
    },
    httpInterceptor: {
      allowedList: [{ uri: `${baseApiUrl}/api/oidc/*` }]
    }
  }
};
