import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@dev-portal/config/provider-tokens';
import { AppWindow } from '@dev-portal/models/app-window';
import { AuthLogoutOptions, AuthService, GenericError } from '@fe/auth';
import { INVALID_REFRESH_TOKEN_ERROR_MESSAGE } from '@fe/common';
import { RuntimeErrorHandler } from '@fe/errors';
import { NotificationService } from '@fe/notifications';

@Injectable()
export class AppRuntimeErrorHandler implements RuntimeErrorHandler {
  private notificationService: NotificationService = inject(NotificationService);
  private authService: AuthService = inject(AuthService);
  private window: AppWindow = inject(WINDOW);

  handle(error: Error): void {
    console.error('Runtime error:', error);
    if (this.isOidcInvalidRefreshTokenError(error)) {
      const options: AuthLogoutOptions = { returnToUrl: this.window.location.origin };
      this.authService.logout$(options).subscribe();
    } else {
      this.notificationService.showError({ title: 'Runtime error:', message: error.message });
    }
  }

  private isOidcInvalidRefreshTokenError(error: Error): boolean {
    return (
      error instanceof GenericError && error.message.includes(INVALID_REFRESH_TOKEN_ERROR_MESSAGE)
    );
  }
}
