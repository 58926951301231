import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { UiMessagesStateModel } from '../models/ui-messages-state-model';

/** @internal */
@Injectable({ providedIn: 'root' })
export class UiMessagesState extends RxState<UiMessagesStateModel> {
  private readonly initialState: UiMessagesStateModel = {
    messages: []
  };

  private get state(): UiMessagesStateModel {
    return this.get();
  }

  constructor() {
    super();
    this.set(this.initialState);
  }

  patch(newState: Partial<UiMessagesStateModel>): void {
    this.set({ ...this.state, ...newState });
  }
}
