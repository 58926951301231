import { Icon, IconName } from './models';

export class NiIconsRegistry {
  private static readonly svgMap: Map<IconName, string> = new Map<IconName, string>();

  static get(iconName: IconName): string | undefined {
    if (!this.svgMap.has(iconName)) {
      throw Error(`😲 Icon "${iconName}" is not registered`);
    }
    return this.svgMap.get(iconName);
  }

  static register(icons: Icon[]): void {
    for (const icon of icons) {
      this.svgMap.set(icon.name, icon.data);
    }
  }
}
