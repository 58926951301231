import { get as _get } from 'radash';

/**
 * Retrieves the value at the specified path of an object. If the value does not exist or is undefined,
 * the defaultValue is returned instead.
 *
 * @param object - The object to retrieve the value from.
 * @param path - The path of the value to retrieve, using dot notation (e.g., 'prop1.prop2').
 * @param defaultValue - The default value to return if the specified path does not exist or if the value is undefined.
 * @returns The value at the specified path, or the defaultValue if the value does not exist or is undefined.
 *
 * @example
 *
 * const fish = {
 *   name: 'Bass',
 *   weight: 8,
 *   sizes: [
 *     {
 *       maturity: 'adult',
 *       range: [7, 18],
 *       unit: 'inches'
 *     }
 *   ]
 * }
 *
 * const maxAdultSize = get(fish, 'sizes[0].range[1]') // => 18
 */
export function get<T extends object, K>(object: T, path: string, defaultValue?: K): K | null {
  return _get(object, path, defaultValue);
}
