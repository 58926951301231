import { Injectable } from '@angular/core';
import { ApiInfo } from '@dev-portal/models/api-info';
import { Application } from '@dev-portal/models/application';
import { ApplicationDetails } from '@dev-portal/models/application-details';
import {
  DeleteApplication,
  ResetApplicationDetails,
  SetApiInfo,
  SetApplicationDetails,
  SetApplications
} from '@dev-portal/store/app.actions';
import { AppState } from '@dev-portal/store/app.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppStore {
  @Select(AppState.applications) applications$!: Observable<Application[]>;
  @Select(AppState.applicationDetails) applicationDetails$!: Observable<ApplicationDetails>;

  constructor(private store: Store) {}

  setApiInfo(apiInfo: ApiInfo): void {
    this.store.dispatch(new SetApiInfo(apiInfo));
  }

  setApplications(applications: Application[]): void {
    this.store.dispatch(new SetApplications(applications));
  }

  setApplicationDetails(applicationDetails: ApplicationDetails): void {
    this.store.dispatch(new SetApplicationDetails(applicationDetails));
  }

  getApiInfo(): ApiInfo {
    return this.store.selectSnapshot(AppState.apiInfo);
  }

  deleteApplication(id: string): void {
    this.store.dispatch(new DeleteApplication(id));
  }

  resetApplicationDetails(): void {
    this.store.dispatch(new ResetApplicationDetails());
  }
}
