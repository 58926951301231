import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AppRouteSegments } from '@dev-portal/config/app-route-segments';
import { AuthService } from '@fe/auth';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): Observable<UrlTree | boolean> {
    return this.redirectIfUnauthenticated$();
  }

  private redirectIfUnauthenticated$(): Observable<UrlTree | boolean> {
    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated: boolean) => {
        return isAuthenticated || this.router.parseUrl(AppRouteSegments.root);
      })
    );
  }
}
