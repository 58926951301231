import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const expandCollapseAnimation: AnimationTriggerMetadata = trigger('expandCollapse', [
  state('expanded', style({ height: '*' })),
  state('collapsed', style({ height: '0', overflow: 'hidden' })),
  transition('collapsed <=> expanded', [style({ overflow: 'hidden' }), animate('150ms ease-in')])
]);
