import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { Type } from 'ng-mocks';
import { MsgTextPipe } from './pipes/msg-text.pipe';

const SHARED_DECLARATIONS: Type<unknown>[] = [MsgTextPipe];

@NgModule({
  imports: [NgIf],
  declarations: SHARED_DECLARATIONS,
  exports: SHARED_DECLARATIONS
})
export class UiMessagesModule {}
