/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@dev-portal/guards/auth.guard';
import { canActivateHomePageGuardFn } from '@dev-portal/guards/can-activate-home-page.guard';
import { ApplicationDetailsPageResolver } from '@dev-portal/resolvers/application-details-page.resolver';
import { HomePageComponent } from './pages/home/home-page.component';
import { AppRouteSegments } from './config/app-route-segments';

const routes: Routes = [
  {
    path: AppRouteSegments.root,
    component: HomePageComponent,
    canActivate: [canActivateHomePageGuardFn]
  },
  {
    path: AppRouteSegments.applications,
    loadChildren: () => {
      return import('./pages/registered-applications/registered-applications-page.module').then(
        (m) => m.RegisteredApplicationsPageModule
      );
    },
    canActivate: [AuthGuard]
  },
  {
    path: `${AppRouteSegments.applications}/:id`,
    resolve: { data: ApplicationDetailsPageResolver },
    loadChildren: () => {
      return import('./pages/application-details/application-details-page.module').then(
        (m) => m.ApplicationDetailsPageModule
      );
    },
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: `/${AppRouteSegments.root}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
