import { ModuleWithProviders, NgModule } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ScrollModuleConfig } from './models/scroll-module-config';
import { CONFIG } from './config/tokens';

@NgModule({ imports: [ScrollToModule.forRoot()] })
export class ScrollModule {
  static forRoot(config: ScrollModuleConfig = {}): ModuleWithProviders<ScrollModule> {
    return { ngModule: ScrollModule, providers: [{ provide: CONFIG, useValue: config }] };
  }
}
