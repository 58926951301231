<ni-modal-dialog (closeModal)="onClose()">
  <ni-dialog-title>Create New App</ni-dialog-title>
  <ni-dialog-body>
    <form class="new-application-form" novalidate autocomplete="off" (submit)="create($event)">
      <ndp-ctrl-wrapper class="mb-2" label="Application name" key="name">
        <input
          id="name"
          class="form-control"
          niSetFocus
          [maxlength]="validationParams.name.maxLength"
          [formControl]="form.controls.name"
        />
        <span ndpInputHint>
          <span>Max character count is </span>
          <span *ngIf="form.controls.name.value as value">{{ value.length }}/</span>
          <span>{{ validationParams.name.maxLength }}</span>
        </span>
      </ndp-ctrl-wrapper>
    </form>
  </ni-dialog-body>
  <ni-dialog-footer class="w-100 grid">
    <button theme="secondary" class="g-col-6" data-qa="cancel-app-creation-btn" (click)="onClose()">
      Cancel
    </button>
    <button theme="primary" class="g-col-6" data-qa="create-app-btn" (click)="create()">
      Create
    </button>
  </ni-dialog-footer>
</ni-modal-dialog>
