import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ContentChild } from '@angular/core';
import { InputEndItemDirective } from '@dev-portal/directives/input-end-item.directive';
import { InputHintDirective } from '@dev-portal/directives/input-hint.directive';
import { ScrollTarget } from '@fe/common';
import { AbstractCtrlWrapperComponent } from '@fe/forms';
import { Nullish } from '@fe/models';
import { ScrollTargetComponent, ScrollTargetDirective } from '@fe/scroll';
import { TrustHtmlPipe } from '@fe/shared';
import { isNotEmpty } from '@fe/utils';

@Component({
  standalone: true,
  selector: 'ndp-ctrl-wrapper',
  templateUrl: 'ctrl-wrapper.component.html',
  imports: [
    NgIf,
    TrustHtmlPipe,
    InputHintDirective,
    InputEndItemDirective,
    ScrollTargetComponent,
    ScrollTargetDirective
  ],
  host: { class: 'ctrl-wrapper-host' }
})
export class CtrlWrapperComponent extends AbstractCtrlWrapperComponent implements AfterViewInit {
  @ContentChild(InputHintDirective) inputHint: Nullish<InputHintDirective>;
  @ContentChild(InputEndItemDirective) inputSectionEnd: Nullish<InputEndItemDirective>;

  public ScrollTarget: typeof ScrollTarget = ScrollTarget;
  public isRendered: boolean = false;

  ngAfterViewInit(): void {
    this.isRendered = true;
    this.cdr.detectChanges();
  }

  hasValue(): boolean {
    return isNotEmpty(this.control.value);
  }
}
