import {
  AbstractControl as AngAbstractControl,
  ValidationErrors as AngValidationErrors
} from '@angular/forms';
import { Nullish } from '@fe/models';
import { TranslationParams, TranslationsProvider } from '@fe/translations';
import { getFirstItem } from '@fe/utils';
import { CTRL_MESSAGE_TRANSLATIONS_KEY } from '../config/consts';
import { AbstractControl } from '../models/abstract-control';
import { CtrlMessage } from '../models/ctrl-message';
import { CtrlMessageType } from '../models/ctrl-message-type';
import { ExternalCtrlMessageKey } from '../models/external-ctrl-message-key';
import { NgControl } from '../models/ng-control';
import { SynchronizeFormArrayParams } from '../models/synchronize-form-array-params';

export class FormUtils {
  static mergeToCtrlErrors(
    controlErrors: AngValidationErrors | null,
    ctrlMessage: CtrlMessage
  ): AngValidationErrors {
    const controlMessages: AngValidationErrors = { [ExternalCtrlMessageKey.$error]: ctrlMessage };
    return Object.assign(controlErrors || {}, controlMessages);
  }

  static getCtrlMessageToDisplay(control: AbstractControl): Nullish<CtrlMessage> {
    if (!FormUtils.hasErrorsToDisplay(control)) {
      return null;
    }
    const errorName: Nullish<string> = this.getErrorNameToDisplay(control);
    return errorName ? this.getCtrlMessageByErrorName(control, errorName) : null;
  }

  static hasErrorsToDisplay(control: AbstractControl | NgControl | null): boolean {
    return control ? !control.pending && !control.valid && !!control.touched : false;
  }

  static syncFormArrayWithItems<TItem, TControl extends AngAbstractControl>(
    params: SynchronizeFormArrayParams<TItem, TControl>
  ): void {
    // Loop through each item and update/create the corresponding control.
    const options: { emitEvent?: boolean } = { emitEvent: params.emitEvents };
    params.items.forEach((item: TItem, itemIndex: number) => {
      const matchedCtrl: TControl | undefined = params.formArray.controls.find(
        (ctrl: TControl, ctrlIndex: number) => {
          return params.isControlMatchesItem(ctrl, item, ctrlIndex, itemIndex);
        }
      );
      if (matchedCtrl) {
        matchedCtrl.setValue(params.createControlValue(item, itemIndex), options);
      } else {
        params.formArray.push(params.createControl(item), options);
      }
    });
    // Loop through each control in the form array and remove it if it does not exist in the item.
    for (
      let ctrlIndex: number = params.formArray.controls.length - 1;
      ctrlIndex >= 0;
      ctrlIndex--
    ) {
      const ctrl: TControl = params.formArray.controls[ctrlIndex];
      const matchedItem: TItem | undefined = params.items.find((item: TItem, itemIndex: number) => {
        return params.isControlMatchesItem(ctrl, item, ctrlIndex, itemIndex);
      });
      if (!matchedItem) {
        params.formArray.removeAt(ctrlIndex, options);
      }
    }
  }

  private static getCtrlMessageByErrorName(
    control: AbstractControl,
    errorName: string
  ): CtrlMessage {
    const errors: AngValidationErrors | null = control.errors || {};
    if (FormUtils.isExternalErrorKey(errorName)) {
      return errors[errorName];
    }
    const path: string = `${CTRL_MESSAGE_TRANSLATIONS_KEY}.${errorName}`;
    let messageText: string = errorName;
    if (TranslationsProvider.has(path)) {
      const params: TranslationParams = errors[errorName];
      messageText = TranslationsProvider.get(path, params);
    }
    return { text: messageText, type: CtrlMessageType.ERROR };
  }

  private static getErrorNameToDisplay(control: AbstractControl): Nullish<string> {
    const keys: string[] = Object.keys(control.errors || {});
    const sortedKeys: string[] = FormUtils.sortErrorKeys(keys);
    return getFirstItem(sortedKeys);
  }

  private static sortErrorKeys(keys: string[]): string[] {
    return keys.sort((key: string) => {
      return FormUtils.isExternalErrorKey(key) ? -1 : 1;
    });
  }

  private static isExternalErrorKey(key: string): boolean {
    return (Object.values(ExternalCtrlMessageKey) as string[]).includes(key);
  }
}
