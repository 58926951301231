import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { ButtonTheme } from '../models/button-theme';
import { ButtonType } from '../models/button-type';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button',
  template: `<ng-content></ng-content>`,
  host: {
    '[class.ni-btn]': 'type === "button"',
    '[class.ni-icon-btn]': 'type === "icon"',
    '[attr.type]': '"button"',
    '[attr.tabindex]': 'disabled ? null : tabIndex',
    '[attr.disabled]': 'disabled || null',
    '[class.disabled]': 'disabled'
  }
})
export class NiButtonComponent implements OnInit {
  @HostBinding('class') get themeTypeClass(): string {
    return `btn-${this.theme}`;
  }

  @Input() tabIndex: number = 0;
  @Input() disabled: boolean | string = false; // TODO Use { transform: booleanAttribute } from angular v16
  @Input() theme: ButtonTheme = 'none';
  @Input() type: ButtonType = 'button';

  constructor(private hostElemRef: ElementRef) {}

  ngOnInit(): void {
    this.initDisabledProperty();
  }

  private initDisabledProperty(): void {
    const btnNativeElem: HTMLButtonElement = this.hostElemRef.nativeElement;
    const disabledAttrValue: string | null = btnNativeElem.getAttribute('disabled');
    if (!this.disabled && !disabledAttrValue) {
      this.disabled = btnNativeElem.hasAttribute('disabled');
    }
  }
}
