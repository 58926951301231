import { NgIf } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CTRL_MESSAGE_PROVIDER } from './config/tokens';
import { InputRefDirective } from './directives/input-ref.directive';
import { FormsModuleConfig } from './models/forms-module-config';
import { NoopCtrlMessageProvider } from './providers/noop-ctrl-message.provider';

const SHARED_DECLARATIONS: Type<unknown>[] = [InputRefDirective];

const SHARED_IMPORTS: Type<unknown>[] = [ReactiveFormsModule];

@NgModule({
  imports: [SHARED_IMPORTS, NgIf],
  declarations: SHARED_DECLARATIONS,
  exports: [SHARED_DECLARATIONS, SHARED_IMPORTS]
})
export class FormsModule {
  static forRoot(config?: FormsModuleConfig): ModuleWithProviders<FormsModule> {
    return {
      ngModule: FormsModule,
      providers: [
        {
          provide: CTRL_MESSAGE_PROVIDER,
          useClass: config?.ctrlMessageProvider || NoopCtrlMessageProvider
        }
      ]
    };
  }
}
