import { InjectionToken } from '@angular/core';
import { AppEnvironment, AvailableEnvironments } from '@dev-portal/environments';
import { AppStateModel } from '@dev-portal/models/app-state-model';
import { AppWindow } from '@dev-portal/models/app-window';
import { StateToken } from '@ngxs/store';

export const ENV: InjectionToken<AppEnvironment> = new InjectionToken<AppEnvironment>('ENV');

export const AVAILABLE_ENVS: InjectionToken<AvailableEnvironments> =
  new InjectionToken<AvailableEnvironments>('AVAILABLE_ENVS');

export const WINDOW: InjectionToken<AppWindow> = new InjectionToken<AppWindow>('WINDOW', {
  factory: (): AppWindow => self as unknown as AppWindow
});

export const APP_STATE_KEY: string = 'root';

export const APP_STATE_TOKEN: StateToken<AppStateModel> = new StateToken<AppStateModel>(
  APP_STATE_KEY
);
