import { GlobalConfig } from 'ngx-toastr/toastr/toastr-config';
import { DefaultNotificationComponent } from '../components/default/default-notification.component';

export const DEFAULT_TOASTR_MODULE_CONFIG: Partial<GlobalConfig> = {
  closeButton: true,
  disableTimeOut: false,
  newestOnTop: false,
  tapToDismiss: false,
  enableHtml: true,
  toastClass: 'toast',
  positionClass: 'toast-top-right',
  toastComponent: DefaultNotificationComponent
};
