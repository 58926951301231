import { JsonPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ErrorHandler,
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  Type
} from '@angular/core';
import {
  DEFAULT_HTTP_ERROR_HANDLERS,
  HTTP_ERROR_HANDLERS,
  RUNTIME_ERROR_HANDLER
} from './config/tokens';
import { DefaultHttpErrorHandler } from './error-handlers/default-http-error.handler';
import { DefaultRuntimeErrorHandler } from './error-handlers/default-runtime-error.handler';
import { GlobalErrorHandler } from './global-error.handler';
import { ErrorsModuleConfig } from './models/errors-module-config';
import { HttpErrorHandler } from './models/http-error.handler';
import { HttpErrorHandlersProvider } from './provider/http-error-handlers.provider';

@NgModule({
  imports: [NgIf, NgSwitch, NgSwitchCase, JsonPipe]
})
export class ErrorsModule {
  static forRoot(config?: ErrorsModuleConfig): ModuleWithProviders<ErrorsModule> {
    return {
      ngModule: ErrorsModule,
      providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
          provide: DEFAULT_HTTP_ERROR_HANDLERS,
          useClass: DefaultHttpErrorHandler,
          multi: true
        },
        ...(config?.httpErrorHandlers || []).map((handlerClass: Type<HttpErrorHandler>) => {
          return { provide: HTTP_ERROR_HANDLERS, useClass: handlerClass, multi: true };
        }),
        {
          provide: RUNTIME_ERROR_HANDLER,
          useClass: config?.runtimeErrorHandler || DefaultRuntimeErrorHandler
        }
      ]
    };
  }

  constructor(
    @Optional() @SkipSelf() existingInstance: ErrorsModule,
    @Optional() @Inject(HTTP_ERROR_HANDLERS) private customHandlers: HttpErrorHandler[],
    @Inject(DEFAULT_HTTP_ERROR_HANDLERS) private defaultHandlers: HttpErrorHandler[]
  ) {
    if (existingInstance) {
      throw new Error('ErrorsModule is already loaded. Import it in the AppModule only');
    }
    HttpErrorHandlersProvider.setDefault(defaultHandlers);
    HttpErrorHandlersProvider.setCustom(customHandlers);
  }
}
