import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UiMessage } from '../models/ui-message';
import { UiMessagesState } from './ui-messages.state';

@Injectable({ providedIn: 'root' })
export class UiMessagesStore {
  public messages$: Observable<UiMessage[]> = this.state.select('messages');

  constructor(private state: UiMessagesState) {}

  setMessages(messages: UiMessage[]): void {
    this.state.patch({ messages });
  }

  getMessages(): UiMessage[] {
    return this.state.get('messages');
  }
}
