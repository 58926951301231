import { Injectable } from '@angular/core';
import { HttpError } from '../models/http-error';
import { ErrorsState } from './errors.state';

@Injectable({ providedIn: 'root' })
export class ErrorsStore {
  constructor(private state: ErrorsState) {}

  setHttpError(httpError: HttpError): void {
    this.state.patch({ httpError });
  }

  getHttpError(): HttpError | null {
    return this.state.get('httpError');
  }
}
