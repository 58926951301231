import { IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CtrlWrapperComponent } from '@dev-portal/components/ctrl-wrapper/ctrl-wrapper.component';
import { PageContentComponent } from '@dev-portal/components/page-content/page-content.component';
import { CreateApplicationDialogComponent } from '@dev-portal/dialogs/create-app/create-application-dialog.component';
import { InputHintDirective } from '@dev-portal/directives/input-hint.directive';
import { availableEnvironments, environment } from '@dev-portal/environments';
import { AppRuntimeErrorHandler } from '@dev-portal/error-handlers';
import { AppUrlState } from '@dev-portal/models/app-url-state';
import { AppHttpErrorMessagesExtractor } from '@dev-portal/services/app-error-message.extractor';
import { AppInitializerService } from '@dev-portal/services/app-initializer.service';
import { AppState } from '@dev-portal/store/app.state';
import { NiButtonComponent } from '@fe-ui/buttons';
import { AuthHttpInterceptor, AuthModule } from '@fe/auth';
import { GlobalHttpErrorHandler, HTTP_ERROR_MESSAGES_EXTRACTOR } from '@fe/common';
import { DialogModule } from '@fe/dialogs';
import { ErrorsModule } from '@fe/errors';
import { FormsModule } from '@fe/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UiMessagesModule } from '@fe/messages';
import { NotificationsModule, ToastOverlayContainerComponent } from '@fe/notifications';
import { DefaultRouterStateSerializer } from '@fe/router';
import { ScrollModule } from '@fe/scroll';
import { SetFocusDirective } from '@fe/shared';
import { UiLockerModule } from '@fe/ui-locker';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastContainerDirective } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AVAILABLE_ENVS, ENV } from './config/provider-tokens';
import { HomePageComponent } from './pages/home/home-page.component';

@NgModule({
  declarations: [AppComponent, HomePageComponent, CreateApplicationDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PageHeaderComponent,
    AppRoutingModule,
    HttpClientModule,
    NiButtonComponent,
    ToastContainerDirective,
    ToastOverlayContainerComponent,
    NgOptimizedImage,
    PageContentComponent,
    UiMessagesModule,
    CtrlWrapperComponent,
    FormsModule.forRoot(),
    ErrorsModule.forRoot({
      httpErrorHandlers: [GlobalHttpErrorHandler],
      runtimeErrorHandler: AppRuntimeErrorHandler
    }),
    UiLockerModule.forRoot(),
    DialogModule.forRoot(),
    BsDropdownModule.forRoot(),
    NotificationsModule.forRoot(),
    ScrollModule.forRoot({ defaultOptions: { offset: -100 } }),
    AuthModule.forRoot(environment.auth0Config),
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.prod,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false
      },
      executionStrategy: NoopNgxsExecutionStrategy
    }),
    NgxsRouterPluginModule.forRoot(),
    InputHintDirective,
    SetFocusDirective
  ],
  providers: [
    { provide: ENV, useValue: environment },
    { provide: AVAILABLE_ENVS, useValue: availableEnvironments },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: RouterStateSerializer, useClass: DefaultRouterStateSerializer<AppUrlState> },
    { provide: HTTP_ERROR_MESSAGES_EXTRACTOR, useClass: AppHttpErrorMessagesExtractor },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig): string => {
        return `${environment.paths.images}/${config.src}`;
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: function appInitializer(initializerService: AppInitializerService): () => void {
        return () => initializerService.onAppInitialization();
      },
      deps: [AppInitializerService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private config: NgSelectConfig) {
    this.config.bindValue = 'value';
    this.config.bindLabel = 'label';
  }
}
