import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AppRouteSegments } from '@dev-portal/config/app-route-segments';
import { AuthService } from '@fe/auth';
import { map, Observable } from 'rxjs';

export const canActivateHomePageGuardFn: CanActivateFn = (): Observable<UrlTree | boolean> => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  return authService.isAuthenticated$.pipe(
    map((isAuthenticated: boolean) => {
      return isAuthenticated
        ? router.parseUrl(`${AppRouteSegments.root}/${AppRouteSegments.applications}`)
        : true;
    })
  );
};
