import { Component, OnInit, TemplateRef } from '@angular/core';
import { Nullish } from '@fe/models';
import { AbstractModalDialogComponent } from '../../abstract-modal-dialog-component';
import { ConfirmationDialogInitialState } from '../../models/confirmation-dialog-initial-state';

const DEFAULT_INITIAL_STATE: ConfirmationDialogInitialState = {
  bodyTextOrTemplate: '',
  cancelBtnText: 'Cancel',
  confirmBtnText: 'Confirm'
};

@Component({
  templateUrl: 'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent extends AbstractModalDialogComponent implements OnInit {
  public initialState: Nullish<ConfirmationDialogInitialState>;
  public titleTemplate: Nullish<TemplateRef<unknown>>;
  public bodyTemplate: Nullish<TemplateRef<unknown>>;
  public templateContext!: object | null;

  ngOnInit(): void {
    this.initialState = { ...DEFAULT_INITIAL_STATE, ...this.initialState };
    this.titleTemplate = this.isTemplate(this.initialState.titleTextOrTemplate)
      ? (this.initialState.titleTextOrTemplate as TemplateRef<unknown>)
      : null;
    this.bodyTemplate = this.isTemplate(this.initialState.bodyTextOrTemplate)
      ? (this.initialState.bodyTextOrTemplate as TemplateRef<unknown>)
      : null;
    this.templateContext = this.initialState.templateContext as object;
  }

  private isTemplate(value: string | TemplateRef<unknown> | undefined): boolean {
    return value instanceof TemplateRef;
  }
}
