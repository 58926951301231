import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { NiButtonComponent } from '@fe-ui/buttons';
import { SetFocusDirective } from '@fe/shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './components/confirmation/confirmation-dialog.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { DialogBodyComponent } from './components/dialog-body.component';
import { DialogFooterComponent } from './components/dialog-footer.component';
import { DialogTitleComponent } from './components/dialog-title.component';

const SHARED_DECLARATIONS: Type<unknown>[] = [
  ModalDialogComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogTitleComponent,
  ConfirmationDialogComponent
];

const SHARED_IMPORTS: Type<unknown>[] = [ModalModule];

@NgModule({
  imports: [SHARED_IMPORTS, CommonModule, NiButtonComponent, SetFocusDirective],
  declarations: [SHARED_DECLARATIONS],
  exports: [SHARED_IMPORTS, SHARED_DECLARATIONS]
})
export class DialogModule {
  static forRoot(): ModuleWithProviders<DialogModule> {
    const { providers }: ModuleWithProviders<ModalModule> = ModalModule.forRoot();
    return { ngModule: DialogModule, providers };
  }
}
