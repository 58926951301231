import { Injectable } from '@angular/core';
import { Nullish } from '@fe/models';
import { TranslationsProvider } from '@fe/translations';
import { UI_MESSAGE_TRANSLATIONS_KEY } from '../config/consts';
import { UiMessage } from '../models/ui-message';
import { UiMessageParams } from '../models/ui-message-params';

@Injectable({ providedIn: 'root' })
export class UiMessagesService {
  getMessageText(message: Nullish<UiMessage>): string {
    const translationKeyOrText: string = message?.translationKeyOrText || '';
    const path: string = `${UI_MESSAGE_TRANSLATIONS_KEY}.${translationKeyOrText}`;
    if (TranslationsProvider.has(path)) {
      const params: UiMessageParams = message?.params || {};
      return TranslationsProvider.get(path, params);
    }
    return translationKeyOrText;
  }
}
