<div class="modal-container">
  <div class="modal-header">
    <h5 *ngIf="titleContent" class="modal-title pull-left">
      <ng-content select="ni-dialog-title"></ng-content>
    </h5>
    <div *ngIf="closable" class="btn close" (click)="close()">
      <span class="fa-solid fa-xmark"></span>
    </div>
  </div>
  <div class="modal-body">
    <ng-content select="ni-dialog-body"></ng-content>
  </div>
  <div class="modal-footer">
    <div *ngIf="footerContent" class="modal-footer-content">
      <ng-content select="ni-dialog-footer"></ng-content>
    </div>
  </div>
</div>
