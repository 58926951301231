import { ModuleWithProviders, NgModule } from '@angular/core';
import { NiIconsRegistry, nsClose } from '@fe/icons';
import { ToastrModule } from 'ngx-toastr';
import { DEFAULT_TOASTR_MODULE_CONFIG } from './config/default-toastr-module-config';
import { NotificationsConfig } from './models/module';

@NgModule({
  imports: [ToastrModule],
  exports: [ToastrModule]
})
export class NotificationsModule {
  static forRoot(config?: NotificationsConfig): ModuleWithProviders<NotificationsModule> {
    config = { ...DEFAULT_TOASTR_MODULE_CONFIG, ...config };
    const { providers }: ModuleWithProviders<ToastrModule> = ToastrModule.forRoot(config);
    return { ngModule: NotificationsModule, providers };
  }

  constructor() {
    NiIconsRegistry.register([nsClose]);
  }
}
