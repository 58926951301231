import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Nullish } from '@fe/models';
import { UiMessage } from '../models/ui-message';
import { UiMessagesService } from '../services/ui-messages.service';

@Pipe({ name: 'msgText', pure: true })
export class MsgTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private uiMessageService: UiMessagesService) {}

  transform(message: Nullish<UiMessage>): SafeHtml | string {
    const text: string = this.uiMessageService.getMessageText(message);
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
