import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpError } from './models/http-error';

@Injectable({ providedIn: 'root' })
export class GlobalErrorEmitter {
  private errorSubject$: Subject<Error | HttpError> = new Subject<Error | HttpError>();

  get error$(): Observable<Error | HttpError> {
    return this.errorSubject$.asObservable();
  }

  emit(error: Error | HttpError): void {
    this.errorSubject$.next(error);
  }
}
