import { AuthScope } from '@dev-portal/models/auth-scope';
import { SelectItem } from '@fe/models';

export class ApplicationScopesProvider {
  private static scopes: AuthScope[] = [];

  static set(scopes: AuthScope[]): void {
    this.scopes = scopes;
  }

  static getAsSelectItems(): SelectItem<string>[] {
    return this.scopes.map((scope: AuthScope) => {
      return { value: scope.value, label: scope.value };
    });
  }
}
