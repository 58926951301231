import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CtrlMessage } from '../models/ctrl-message';
import { CtrlMessageProvider } from '../models/ctrl-message-provider';

@Injectable({ providedIn: 'root' })
export class NoopCtrlMessageProvider implements CtrlMessageProvider {
  errorMessage$(): Observable<CtrlMessage> {
    return of();
  }
}
