import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ standalone: true, selector: 'input[niSetFocus],button[niSetFocus]' })
export class SetFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 200);
  }
}
