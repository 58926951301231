import { Injectable } from '@angular/core';
import { HttpErrorMessagesExtractor } from '@fe/common';
import { HttpError } from '@fe/errors';
import { UiMessage, UiMessageFactory } from '@fe/messages';
import { Nullish } from '@fe/models';
import { isEmpty } from '@fe/utils';

@Injectable()
export class AppHttpErrorMessagesExtractor implements HttpErrorMessagesExtractor {
  extract(httpError: HttpError): UiMessage[] {
    const uiMessages: UiMessage[] = [];
    const responseData: Nullish<string | string[]> = httpError.response.error?.message;

    if (isEmpty(responseData)) {
      return uiMessages;
    }

    const messages: string[] = Array.isArray(responseData)
      ? responseData
      : [responseData as string];

    return messages.map((message: string) => {
      return UiMessageFactory.globalErrorFromArgs({
        translationKeyOrText: message
      });
    });
  }
}
