import { Injectable } from '@angular/core';
import { APP_STATE_TOKEN } from '@dev-portal/config/provider-tokens';
import { ApiInfo } from '@dev-portal/models/api-info';
import { AppStateModel } from '@dev-portal/models/app-state-model';
import { Application } from '@dev-portal/models/application';
import { ApplicationDetails } from '@dev-portal/models/application-details';
import {
  DeleteApplication,
  ResetApplicationDetails,
  SetApiInfo,
  SetApplicationDetails,
  SetApplications
} from '@dev-portal/store/app.actions';
import { Nullish } from '@fe/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export const initialState: AppStateModel = {
  apiInfo: { scopes: [] },
  applications: [],
  applicationDetails: null
};

@State<AppStateModel>({
  name: APP_STATE_TOKEN,
  defaults: { ...initialState }
})
@Injectable()
export class AppState {
  @Selector()
  static apiInfo(state: AppStateModel): ApiInfo {
    return state.apiInfo;
  }

  @Selector()
  static applications(state: AppStateModel): Application[] {
    return state.applications;
  }

  @Selector()
  static applicationDetails(state: AppStateModel): Nullish<ApplicationDetails> {
    return state.applicationDetails;
  }

  @Action(SetApiInfo)
  setApiInfo(ctx: StateContext<AppStateModel>, action: SetApiInfo): void {
    ctx.patchState({ apiInfo: action.apiInfo });
  }

  @Action(SetApplicationDetails)
  setApplicationDetails(ctx: StateContext<AppStateModel>, action: SetApplicationDetails): void {
    ctx.patchState({ applicationDetails: action.applicationDetails });
  }

  @Action(SetApplications)
  setApplications(ctx: StateContext<AppStateModel>, action: SetApplications): void {
    ctx.patchState({ applications: action.applications });
  }

  @Action(DeleteApplication)
  deleteApplication(ctx: StateContext<AppStateModel>, action: DeleteApplication): void {
    const state: AppStateModel = ctx.getState();
    ctx.patchState({
      applications: state.applications.filter((application: Application) => {
        return application.id !== action.applicationId;
      })
    });
  }

  @Action(ResetApplicationDetails)
  resetApplicationDetails(ctx: StateContext<AppStateModel>): void {
    ctx.patchState({ applicationDetails: initialState.applicationDetails });
  }
}
