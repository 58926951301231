/**
 * @returns Returns first item from array if exists, else null.
 * @example
 *
 * getFirstItem(null) // => null
 * getFirstItem([]) // => null
 * getFirstItem([{ 'a': 7 }, { 'b': 2 }]) // => { 'a': 7 }
 */
export function getFirstItem<T>(arr: T[]): T | null {
  return arr && arr.length ? arr[0] : null;
}

/**
 * @returns Returns last item from array if exists, else null.
 * @example
 *
 * getFirstItem(null) // => null
 * getFirstItem([]) // => null
 * getFirstItem([{ 'a': 7 }, { 'b': 2 }]) // => { 'b': 2 }
 */
export function getLastItem<T>(arr: T[]): T | null {
  return arr && arr.length ? arr[arr.length - 1] : null;
}
