import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { SCROLL_TARGET_NAME_ATTRIBUTE } from '../config/constants';

@Component({
  standalone: true,
  selector: 'ni-scroll-target',
  template: '',
  styleUrls: ['scroll-target.component.scss']
})
export class ScrollTargetComponent implements OnInit {
  @Input() name!: string;

  constructor(private host: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setAttribute(this.host.nativeElement, SCROLL_TARGET_NAME_ATTRIBUTE, this.name);
  }
}
