import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpInterceptor as Auth0HttpInterceptor } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private auth0HttpInterceptor: Auth0HttpInterceptor) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.auth0HttpInterceptor.intercept(req, next);
  }
}
