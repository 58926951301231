import {
  AsyncValidatorFn as AngAsyncValidatorFn,
  FormControl as AngFormControl,
  FormControlOptions as AngFormControlOptions,
  FormControlState as AngFormControlState,
  ValidatorFn as AngValidatorFn
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { DEFAULT_CONTROL_SET_VALUE_OPTIONS } from '../config/default-control-set-value-options';
import { DEFAULT_MARK_AS_OPTION } from '../config/default-mark-as-options';
import { ControlMarkAsOptions } from './control-mark-as-options';
import { ControlSetValueOptions } from './control-set-value-options';

export class FormControl<TValue> extends AngFormControl {
  public touchedChanges: Observable<boolean>;
  public override valueChanges!: Observable<TValue>;
  public override value!: TValue;

  private touchedChangesSubject$: Subject<boolean>;

  constructor(
    state: AngFormControlState<TValue> | TValue,
    validatorOrOpts?: AngValidatorFn | AngValidatorFn[] | AngFormControlOptions | null,
    asyncValidator?: AngAsyncValidatorFn | AngAsyncValidatorFn[] | null
  ) {
    super(state, validatorOrOpts, asyncValidator);
    this.touchedChangesSubject$ = new Subject<boolean>();
    this.touchedChanges = this.touchedChangesSubject$.asObservable();
  }

  override setValue(value: TValue, options: ControlSetValueOptions = {}): void {
    options = { ...DEFAULT_CONTROL_SET_VALUE_OPTIONS, ...options };
    super.setValue(value, options);
  }

  override patchValue(value: TValue, options: ControlSetValueOptions = {}): void {
    options = { ...DEFAULT_CONTROL_SET_VALUE_OPTIONS, ...options };
    super.setValue(value, options);
  }

  override markAsTouched(options: ControlMarkAsOptions = {}): void {
    options = { ...DEFAULT_MARK_AS_OPTION, ...options };
    super.markAsTouched(options);
    if (options.emitEvent) {
      this.touchedChangesSubject$.next(true);
    }
  }

  override markAsUntouched(options: ControlMarkAsOptions = {}): void {
    options = { ...DEFAULT_MARK_AS_OPTION, ...options };
    super.markAsUntouched(options);
    if (options.emitEvent) {
      this.touchedChangesSubject$.next(false);
    }
  }
}
