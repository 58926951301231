import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UiLockerStore } from '../store/ui-locker.store';

@Injectable({ providedIn: 'root' })
export class UiLockerService {
  constructor(private store: UiLockerStore) {}

  lock(): void {
    this.store.setLocked(true);
  }

  unlock(): void {
    this.store.setLocked(false);
  }

  withLock$<T>(observableFactory$: () => Observable<T>): Observable<T> {
    this.lock();
    return observableFactory$().pipe(finalize(() => this.unlock()));
  }
}
