import { HttpStatusCode } from '@angular/common/http';
import { Nullish } from '@fe/models';
import { HttpErrorHandler } from '../models/http-error.handler';

export class HttpErrorHandlersProvider {
  private static defaultHandlers: HttpErrorHandler[] = [];
  private static customHandlers: HttpErrorHandler[] = [];

  static setDefault(handlers: Nullish<HttpErrorHandler[]>): void {
    HttpErrorHandlersProvider.defaultHandlers = handlers || [];
  }

  static setCustom(handlers: Nullish<HttpErrorHandler[]>): void {
    HttpErrorHandlersProvider.customHandlers = handlers || [];
  }

  static findHandler(statusCode: HttpStatusCode): Nullish<HttpErrorHandler> {
    const customHandler: Nullish<HttpErrorHandler> =
      HttpErrorHandlersProvider.findCustomHandler(statusCode);
    return customHandler ? customHandler : HttpErrorHandlersProvider.findDefaultHandler(statusCode);
  }

  private static findCustomHandler(statusCode: HttpStatusCode): Nullish<HttpErrorHandler> {
    return HttpErrorHandlersProvider.customHandlers.find((handler: HttpErrorHandler) => {
      return handler.match(statusCode);
    });
  }

  private static findDefaultHandler(statusCode: HttpStatusCode): Nullish<HttpErrorHandler> {
    return HttpErrorHandlersProvider.defaultHandlers.find((handler: HttpErrorHandler) => {
      return handler.match(statusCode);
    });
  }
}
