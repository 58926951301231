import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName],[formControl],[ngModel]'
})
export class InputRefDirective {
  public id: string;
  public tagName: string;

  constructor(private element: ElementRef) {
    this.id = element.nativeElement.id;
    this.tagName = element.nativeElement.tagName.toLowerCase();
  }
}
