import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Nullish } from '@fe/models';
import { RUNTIME_ERROR_HANDLER } from './config/tokens';
import { GlobalErrorEmitter } from './global-error.emitter';
import { HttpErrorEmitter } from './http-error.emitter';
import { HttpError } from './models/http-error';
import { HttpErrorHandler } from './models/http-error.handler';
import { RuntimeErrorHandler } from './models/runtime-error.handler';
import { HttpErrorHandlersProvider } from './provider/http-error-handlers.provider';
import { ErrorsStore } from './store/errors.store';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  get globalErrorEmitter(): GlobalErrorEmitter {
    return this.injector.get(GlobalErrorEmitter);
  }

  get httpErrorEmitter(): HttpErrorEmitter {
    return this.injector.get(HttpErrorEmitter);
  }

  get errorsStore(): ErrorsStore {
    return this.injector.get(ErrorsStore);
  }

  get runtimeErrorHandler(): RuntimeErrorHandler {
    return this.injector.get(RUNTIME_ERROR_HANDLER);
  }

  handleError(error: Error | HttpError): void {
    error = this.unwrapFromPromiseIfNeeded(error);
    if (error instanceof HttpError) {
      this.httpErrorEmitter.emit(error);
      this.errorsStore.setHttpError(error);
      this.getHttpErrorHandler(error)?.handle(error);
    } else {
      this.runtimeErrorHandler.handle(error);
    }
    this.globalErrorEmitter.emit(error);
  }

  private getHttpErrorHandler(error: HttpError): Nullish<HttpErrorHandler> {
    return HttpErrorHandlersProvider.findHandler(error.response.status);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private unwrapFromPromiseIfNeeded(error: any): Error | HttpError {
    // https://github.com/angular/angular/issues/27840
    if (error.promise) {
      while (error.rejection) {
        error = error.rejection;
      }
    }
    return error;
  }
}
