import { Nullish } from '@fe/models';
import { get, interpolate } from '@fe/utils';
import { TranslationParams } from '../models/translation-params';
import { Translations } from '../models/translations';

export class TranslationsProvider {
  private static translations: Translations = {};

  static set(translations: Nullish<Translations>): void {
    this.translations = translations || {};
  }

  static has(path: string): boolean {
    return !!get<Translations, string>(this.translations, path);
  }

  static get(path: string, params?: TranslationParams): string {
    const text: Nullish<Translations | string> = get<Translations, string>(this.translations, path);
    if (typeof text !== 'string') {
      return path;
    }
    return params ? interpolate(text, params) : text;
  }
}
