import { ChangeDetectorRef, Component } from '@angular/core';
import { CreateApplicationFormMapper } from '@dev-portal/mappers/create-application-form.mapper';
import { CreateApplicationForm } from '@dev-portal/models/create-application-form';
import { CreateApplicationValidationParams } from '@dev-portal/models/create-application-validation-params';
import { CreateApplicationData } from '@dev-portal/models/create-application-data';
import { AbstractModalDialogComponent } from '@fe/dialogs';
import { Form, FormControl, Validators } from '@fe/forms';

@Component({
  templateUrl: 'create-application-dialog.component.html'
})
export class CreateApplicationDialogComponent extends AbstractModalDialogComponent {
  public form: Form<CreateApplicationForm>;
  public validationParams: CreateApplicationValidationParams = {
    name: { maxLength: 50 }
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.form = new Form<CreateApplicationForm>({
      name: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.validationParams.name.maxLength)
      ])
    });
  }

  create(event?: Event): void {
    event?.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const data: CreateApplicationData = CreateApplicationFormMapper.toCreateApplicationData(
      this.form.getRawValue()
    );
    this.onConfirm(data);
  }
}
