import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NiIconComponent } from '@fe/icons';
import { NotificationMessageState } from '../../models/notification-message-state';
import { AbstractNotificationComponent } from '../abstract-notification.component';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[custom-toast-component]',
  imports: [NgIf, NiIconComponent],
  templateUrl: 'default-notification.component.html'
})
export class DefaultNotificationComponent extends AbstractNotificationComponent {
  public override initialState?: NotificationMessageState;
}
