import { Injectable } from '@angular/core';
import { translations } from '@dev-portal/config/translations';
import { AppMessagesService } from '@dev-portal/services/app-messages.service';
import {
  NiIconsRegistry,
  nsArrowLeft,
  nsCopy,
  nsDelete,
  nsEdit,
  nsVisibility,
  nsVisibilityOff
} from '@fe/icons';
import { UiMessage, UiMessagesEmitter } from '@fe/messages';
import { TranslationsProvider } from '@fe/translations';

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  constructor(
    private uiMessagesEmitter: UiMessagesEmitter,
    private appMessagesService: AppMessagesService
  ) {}

  onAppInitialization(): void {
    this.initIcons();
    TranslationsProvider.set(translations);
    this.uiMessagesEmitter.messages$.subscribe((messages: UiMessage[]) => {
      this.appMessagesService.showMessages(messages);
    });
  }

  private initIcons(): void {
    NiIconsRegistry.register([
      nsArrowLeft,
      nsCopy,
      nsDelete,
      nsEdit,
      nsVisibility,
      nsVisibilityOff
    ]);
  }
}
