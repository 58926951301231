import { Component, Inject } from '@angular/core';
import { ENV } from '@dev-portal/config/provider-tokens';
import { AppEnvironment } from '@dev-portal/environments';

@Component({
  selector: 'ndp-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(@Inject(ENV) private env: AppEnvironment) {
    this.logAppVersion();
  }

  private logAppVersion(): void {
    console.log(
      `%cApp version: ${this.env.version}`,
      'background-color: yellow; font-weight: bold'
    );
  }
}
