/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 */
export function isNullish(value: any): boolean {
  return value == null;
}

export function isNotNullish(value: any): boolean {
  return !isNullish(value);
}
