import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpError } from './models/http-error';

@Injectable({ providedIn: 'root' })
export class HttpErrorEmitter {
  private errorSubject$: Subject<HttpError> = new Subject<HttpError>();

  get error$(): Observable<HttpError> {
    return this.errorSubject$.asObservable();
  }

  emit(error: HttpError): void {
    this.errorSubject$.next(error);
  }
}
