<div class="container-md" (resize)="isCollapsed = true">
  <nav
    class="navbar navbar-horizontal navbar-expand-md d-flex justify-content-between py-1"
    aria-label="navigation"
  >
    <button class="navbar-toggler" theme="secondary" type="button" (click)="toggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <img ngSrc="nursa-logo-and-name.svg" class="d-block" width="190" height="40" priority alt />
    <div class="d-none d-md-block">
      <ng-container [ngTemplateOutlet]="envSwitcherTmpl"></ng-container>
    </div>
    <div class="d-none d-md-block">
      <ng-container [ngTemplateOutlet]="rightSideTmpl"></ng-container>
    </div>
  </nav>
  <div [@expandCollapse]="isCollapsed ? 'collapsed' : 'expanded'">
    <nav class="navbar navbar-vertical d-md-none" aria-label="navigation">
      <ng-container [ngTemplateOutlet]="envSwitcherTmpl"></ng-container>
      <ng-container [ngTemplateOutlet]="rightSideTmpl"></ng-container>
    </nav>
  </div>
</div>

<ng-template #envSwitcherTmpl>
  <ul class="navbar-nav">
    <li *ngFor="let envLink of environmentLinks" class="nav-item">
      <a
        class="nav-link"
        [attr.data-qa]="'env-link-' + envLink.name"
        [attr.href]="envLink.active ? null : envLink.host"
        [class.active]="envLink.active"
      >
        {{ envLink.name }}
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #rightSideTmpl>
  <div class="text-end">
    <button
      *ngIf="!authState.isAuthenticated"
      type="button"
      theme="primary"
      data-qa="login-btn"
      (click)="login()"
    >
      Login
    </button>

    <div *ngIf="authState.isAuthenticated" dropdown container="body" class="user-menu">
      <div dropdownToggle class="clickable dropdown-toggle" data-qa="user-profile-icon">
        <div class="user-circle">
          <span>{{ userNameInitials }}</span>
        </div>
      </div>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right mt-0-5">
        <li class="clickable" data-qa="logout-btn" (click)="logout()">
          <a class="dropdown-item">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
