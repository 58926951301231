import { Inject, Injectable } from '@angular/core';
import {
  FormControlStatus as AngFormControlStatus,
  ValidationErrors as AngValidationErrors
} from '@angular/forms';
import { Nullish } from '@fe/models';
import { combineLatest, Observable, startWith, Subject, Subscriber, takeUntil } from 'rxjs';
import { CTRL_MESSAGE_PROVIDER } from '../config/tokens';
import { AbstractControl } from '../models/abstract-control';
import { CtrlMessage } from '../models/ctrl-message';
import { CtrlMessageProvider } from '../models/ctrl-message-provider';
import { FormUtils } from '../utils/form.utils';

@Injectable({ providedIn: 'root' })
export class ControlsService {
  constructor(@Inject(CTRL_MESSAGE_PROVIDER) private ctrlMessageProvider: CtrlMessageProvider) {}

  getMessage$(
    control: AbstractControl,
    localMessageKey?: Nullish<string>
  ): Observable<Nullish<CtrlMessage>> {
    const unsubscribe$: Subject<void> = new Subject<void>();

    if (localMessageKey) {
      this.ctrlMessageProvider
        .errorMessage$({ key: localMessageKey })
        .pipe(takeUntil(unsubscribe$))
        .subscribe((message: CtrlMessage) => {
          const sourceErrors: AngValidationErrors | null = control.errors;
          const errors: AngValidationErrors = FormUtils.mergeToCtrlErrors(sourceErrors, message);
          control.setErrors(errors, { emitEvent: false });
          control.markAsTouched();
        });
    }

    return new Observable((observer: Subscriber<Nullish<CtrlMessage>>) => {
      const status$: Observable<AngFormControlStatus> = this.getControlStatus$(control);
      const touched$: Observable<boolean> = this.getControlTouched$(control);
      combineLatest([status$, touched$])
        .pipe(takeUntil(unsubscribe$))
        .subscribe(() => {
          return observer.next(FormUtils.getCtrlMessageToDisplay(control));
        });
      return () => {
        unsubscribe$.next();
        unsubscribe$.complete();
      };
    });
  }

  private getControlStatus$(control: AbstractControl): Observable<AngFormControlStatus> {
    return control.statusChanges.pipe(startWith(control.status));
  }

  private getControlTouched$(control: AbstractControl): Observable<boolean> {
    return control.touchedChanges.pipe(startWith(false));
  }
}
