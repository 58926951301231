import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ndp-page-content',
  templateUrl: 'page-content.component.html',
  styleUrls: ['page-content.component.scss']
})
export class PageContentComponent {}
