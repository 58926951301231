import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

export class HttpError {
  public request: HttpRequest<unknown>;
  public response: HttpErrorResponse;

  constructor(request: HttpRequest<unknown>, response: HttpErrorResponse) {
    this.request = request;
    this.response = response;
  }
}
