import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to/lib/scroll-to-config.interface';
import { TargetSelectorFactory } from '../factories/target-selector.factory';
import { ScrollModuleConfig } from '../models/scroll-module-config';
import { ScrollOptions } from '../models/scroll-options';
import { CONFIG } from '../config/tokens';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  private baseOptions: Partial<ScrollToConfigOptions> = {
    easing: 'easeInQuint',
    duration: 300
  };

  constructor(
    private scrollToService: ScrollToService,
    private ngZone: NgZone,
    @Inject(CONFIG) private config: ScrollModuleConfig,
    @Inject(DOCUMENT) private document: Document
  ) {}

  scrollTo(targetName: string, options: ScrollOptions = {}): void {
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        this.scrollToService.scrollTo({
          ...this.baseOptions,
          ...(this.config.defaultOptions || {}),
          ...options,
          target: this.getTargetElement(targetName)
        });
      });
    }, 200);
  }

  private getTargetElement(targetName: string): HTMLElement {
    const targetSelector: string = TargetSelectorFactory.get(targetName);
    return this.document.querySelector(targetSelector) as HTMLElement;
  }
}
