import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService as Auth0Service, IdToken } from '@auth0/auth0-angular';
import { Nullish } from '@fe/models';
import { WINDOW } from '@fe/shared';
import { createQueryParams, isNotNullish } from '@fe/utils';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { AUTH_CONFIG } from '../config/tokens';
import { AuthConfig } from '../models/auth-config';
import { AuthState } from '../models/auth-state';
import { AuthLoginOptions } from '../models/login-options';
import { AuthLogoutOptions } from '../models/logout-options';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private logoutSubject$: Subject<void> = new Subject();
  private stateSubject$: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>({
    user: null,
    isAuthenticated: null
  });

  public state$: Observable<AuthState> = this.stateSubject$.asObservable();

  get isAuthenticated$(): Observable<boolean> {
    return this.state$.pipe(
      filter((state: AuthState) => isNotNullish(state.isAuthenticated)),
      map((state: AuthState) => !!state.isAuthenticated)
    );
  }

  constructor(
    private auth: Auth0Service,
    @Inject(WINDOW) private window: Window,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig
  ) {
    combineLatest({
      user: this.auth.user$,
      isAuthenticated: this.auth.isAuthenticated$
    })
      .pipe(takeUntil(this.logoutSubject$))
      .subscribe((state: AuthState) => {
        this.stateSubject$.next(state);
      });
  }

  login$(options: AuthLoginOptions): Observable<void> {
    return this.auth.loginWithRedirect({
      appState: { target: options.redirectToUrl }
    });
  }

  logout$(options: AuthLogoutOptions): Observable<void> {
    return this.getIdToken$().pipe(
      switchMap((idToken: string | null) => {
        return this.auth.logout({ openUrl: false }).pipe(
          tap(() => {
            this.logoutSubject$.next();
            if (idToken) {
              const logoutUrl: string = this.buildLogoutUrl(idToken, options);
              this.window.location.replace(logoutUrl);
            } else {
              this.window.location.reload();
            }
          })
        );
      })
    );
  }

  private buildLogoutUrl(idToken: Nullish<string>, options: AuthLogoutOptions): string {
    const params: HttpParams = createQueryParams({
      client_id: this.authConfig.clientId,
      id_token_hint: idToken,
      post_logout_redirect_uri: options.returnToUrl
    });
    return `${this.authConfig.domain}/logout?${params.toString()}`;
  }

  private getIdToken$(): Observable<string | null> {
    return this.auth.idTokenClaims$.pipe(
      map((claims: Nullish<IdToken>) => claims?.__raw ?? null),
      take(1)
    );
  }
}
