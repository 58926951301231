import { ErrorMessageArgs } from '../models/error-message-args';
import { UiMessage } from '../models/ui-message';
import { UiMessageScope } from '../models/ui-message-scope';
import { UiMessageType } from '../models/ui-message-type';

export class UiMessageFactory {
  static localErrorFromArgs(args: ErrorMessageArgs): UiMessage {
    return {
      key: args.key,
      translationKeyOrText: args.translationKeyOrText,
      property: args.property,
      params: args.params,
      scope: UiMessageScope.LOCAL,
      type: UiMessageType.ERROR
    };
  }

  static globalErrorFromArgs(args: ErrorMessageArgs): UiMessage {
    return {
      key: args.key,
      translationKeyOrText: args.translationKeyOrText,
      property: args.property,
      params: args.params,
      scope: UiMessageScope.GLOBAL,
      type: UiMessageType.ERROR
    };
  }
}
