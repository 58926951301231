import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpError, HttpErrorHandler } from '@fe/errors';
import { UiMessage, UiMessagesEmitter, UiMessagesStore } from '@fe/messages';
import { HTTP_ERROR_MESSAGES_EXTRACTOR } from '../config/tokens';
import { HttpErrorMessagesExtractor } from '../models/http-error-messages-extractor';

@Injectable()
export class GlobalHttpErrorHandler implements HttpErrorHandler {
  private uiMessagesEmitter: UiMessagesEmitter = inject(UiMessagesEmitter);
  private uiMessageStore: UiMessagesStore = inject(UiMessagesStore);
  private messagesExtractor: HttpErrorMessagesExtractor = inject(HTTP_ERROR_MESSAGES_EXTRACTOR);

  handle(httpError: HttpError): void {
    const uiMessages: UiMessage[] = this.messagesExtractor.extract(httpError);
    this.uiMessagesEmitter.emit(uiMessages);
    this.uiMessageStore.setMessages(uiMessages);
  }

  match(statusCode: HttpStatusCode): boolean {
    return statusCode >= 400;
  }
}
