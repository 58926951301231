import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { BaseUrlState } from '../models';

@Injectable()
export class DefaultRouterStateSerializer<RState extends BaseUrlState = BaseUrlState>
  implements RouterStateSerializer<RState>
{
  serialize(routerState: RouterStateSnapshot): RState {
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild && state.firstChild.firstChild) {
      state = state.firstChild;
    }
    return {
      url: routerState.url,
      queryParams: routerState.root.queryParams || {},
      pathParams: state.params || {},
      data: state.data || {}
    } as RState;
  }
}
