import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { AuthHttpInterceptor as Auth0HttpInterceptor } from '@auth0/auth0-angular';
import { AUTH_CONFIG } from './config/tokens';
import { AuthConfig } from './models/auth-config';

@NgModule({
  imports: [Auth0Module],
  exports: [Auth0Module]
})
export class AuthModule {
  static forRoot(config?: AuthConfig): ModuleWithProviders<AuthModule> {
    const { providers }: ModuleWithProviders<Auth0Module> = Auth0Module.forRoot(config);
    return {
      ngModule: AuthModule,
      providers: [
        ...(providers || []),
        Auth0HttpInterceptor,
        { provide: AUTH_CONFIG, useValue: config }
      ]
    };
  }
}
