import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  selector: 'ni-toast-overlay-container',
  imports: [ToastContainerDirective],
  template: '<div toastContainer></div>',
  host: { class: 'custom-toast-overlay-container' }
})
export class ToastOverlayContainerComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective;

  constructor(private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
  }
}
