import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AppRouteSegments } from '@dev-portal/config/app-route-segments';
import { AVAILABLE_ENVS, ENV, WINDOW } from '@dev-portal/config/provider-tokens';
import {
  AppEnvironment,
  AvailableEnvironmentItem,
  AvailableEnvironments
} from '@dev-portal/environments';
import { AppWindow } from '@dev-portal/models/app-window';
import { NiButtonComponent } from '@fe-ui/buttons';
import { AuthLoginOptions, AuthLogoutOptions, AuthService, AuthState, User } from '@fe/auth';
import { expandCollapseAnimation } from '@fe/common';
import { Nullish } from '@fe/models';
import { UiLockerService } from '@fe/ui-locker';
import { getFirstItem, getLastItem } from '@fe/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ndp-page-header',
  templateUrl: 'page-header.component.html',
  imports: [
    NgIf,
    AsyncPipe,
    NgOptimizedImage,
    NiButtonComponent,
    NgTemplateOutlet,
    BsDropdownModule,
    NgForOf
  ],
  styleUrls: ['page-header.component.scss'],
  animations: [expandCollapseAnimation]
})
export class PageHeaderComponent implements OnInit {
  public authState!: AuthState;
  public isCollapsed: boolean = true;
  public userNameInitials: Nullish<string>;
  public environmentLinks: AvailableEnvironmentItem[] = [];

  constructor(
    public auth: AuthService,
    private uiLockerService: UiLockerService,
    private cdr: ChangeDetectorRef,
    @Inject(ENV) private env: AppEnvironment,
    @Inject(WINDOW) private window: AppWindow,
    @Inject(AVAILABLE_ENVS) private availableEnvs: AvailableEnvironments
  ) {}

  ngOnInit(): void {
    this.environmentLinks = this.getEnvironmentLinks();
    this.auth.state$.pipe(untilDestroyed(this)).subscribe((state: AuthState) => {
      this.authState = state;
      if (state.isAuthenticated) {
        this.userNameInitials = this.getUserNameInitials(state.user);
      }
      this.cdr.detectChanges();
    });
  }

  login(): void {
    const options: AuthLoginOptions = { redirectToUrl: AppRouteSegments.applications };
    this.uiLockerService.withLock$(() => this.auth.login$(options)).subscribe();
  }

  logout(): void {
    const options: AuthLogoutOptions = { returnToUrl: this.window.location.origin };
    this.uiLockerService.withLock$(() => this.auth.logout$(options)).subscribe();
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  private getUserNameInitials(user: Nullish<User>): string {
    const nameParts: string[] = (user?.name || '').split(' ');
    const firstName: string = getFirstItem(nameParts) || '';
    const lastName: string = getLastItem(nameParts) || '';
    return `${firstName[0] || ''}${lastName[0] || ''}`;
  }

  private getEnvironmentLinks(): AvailableEnvironmentItem[] {
    const currentEnvLink: AvailableEnvironmentItem = { name: this.env.name, active: true };
    return [currentEnvLink, ...this.availableEnvs].sort(
      (envA: AvailableEnvironmentItem, envB: AvailableEnvironmentItem) => {
        return envA.name.localeCompare(envB.name);
      }
    );
  }
}
