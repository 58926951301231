import { isEmpty } from '@fe/utils';
import { KeyToUiMessageMap } from '../models/key-to-ui-message-map';
import { UiMessage } from '../models/ui-message';
import { UiMessageScope } from '../models/ui-message-scope';
import { UiMessageType } from '../models/ui-message-type';

export class UiMessagesUtils {
  static getGlobalErrors(messages: UiMessage[]): UiMessage[] {
    if (isEmpty(messages)) {
      return [];
    }
    return messages.filter((message: UiMessage) => {
      return message.type === UiMessageType.ERROR && message.scope === UiMessageScope.GLOBAL;
    });
  }

  static getKeyToMessageMap(messages: UiMessage[]): KeyToUiMessageMap {
    return messages.reduce((map: KeyToUiMessageMap, message: UiMessage) => {
      if (message.key) {
        map[message.key] = message;
      }
      return map;
    }, {});
  }
}
