import { Injectable, NgZone, Type } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { AbstractNotificationComponent } from '../components/abstract-notification.component';
import { NotificationMessageState } from '../models/notification-message-state';
import { ToastType } from '../models/toast-type';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastrService: ToastrService, private ngZone: NgZone) {}

  show<TComponent extends AbstractNotificationComponent, TState extends TComponent['initialState']>(
    component: Type<TComponent>,
    initialState: TState,
    config?: Partial<IndividualConfig>
  ): void {
    this.ngZone.run(() => {
      const activeToast: ActiveToast<AbstractNotificationComponent> = this.toastrService.show(
        undefined,
        undefined,
        { ...config, toastComponent: component }
      );
      activeToast.toastRef.componentInstance.initialState = initialState;
    });
  }

  showInfo(initialState: NotificationMessageState, config?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => {
      this.toastrService.show(initialState.message, initialState.title, config, ToastType.INFO);
    });
  }

  showWarning(initialState: NotificationMessageState, config?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => {
      this.toastrService.show(initialState.message, initialState.title, config, ToastType.WARNING);
    });
  }

  showError(initialState: NotificationMessageState, config?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => {
      config = { disableTimeOut: true, ...config };
      this.toastrService.show(initialState.message, initialState.title, config, ToastType.ERROR);
    });
  }
}
